<template>
  <div class="page">
    <!-- 新闻中心
       -->
    <div class="content">
      <div class="title">乘风起航, 2021美蜂名医·破局之战</div>
      <div class="time">2021-06-10</div>
      <img src="../../static/news/news_img1.1.png" class="img" />
      <p>
        导语：美蜂入局医美千亿市场流量之争：全国首个整形医师与求美者直接沟通问诊的APP能否突出重围。
      </p>
      <p>
        根据佛若斯特萨利文报告，中国医疗美容服务市场总收入自2013年的869亿元增至2021年的2000亿元，年复合增长率约为23.0%。
        人们享受颜值红利又苦于颜值焦虑，越来越多的年轻人渴望通过医疗美容服务变美。
        随着时代发展，消费者可支配收入提高了购买力，无论是存量还是增量，医美消费总额将继续保持强劲增长。
        然而，在肥美的利益蛋糕面前，从医疗事故到虚假营销，乱象频出，消费者的求美安全和权益无法得到保障。
        中国医美市场急需一位真正将求美者利益放在首位的“救世主”，美蜂名医也由此应运而生。
      </p>
      <h5>国资委控股企业强势入局</h5>
      <p>
        颜值时代，又逢暑假医美旺季。
        从户外大屏广告、小区电梯海报，到手机软件开屏封面，被医美APP的广告霸屏已是常态。
        互联网医美发展至今，时局瞬息万变，早已由最初新氧和更美二分天下更迭为了阿里健康、美团医美等巨头凭借巨大的流量入口和用户基础瓜分市场的局面。
        然而，社区+评价+电商的模式虽能较快获得收益涨幅，却也因黑灰产侵蚀、刷单造假、机构与医生资质不过关、低价引流获客到院却升级消费等层出不穷的负面丑闻引起消费者的信任危机。
      </p>
      <p>
        在群雄逐鹿又乱象频出的互联网医美领域，由国资委（既国有资产监督管理委员会）控股成立的美蜂互联网公司强势入局。
        依托强大的供应链基础、尖端的互联网技术、高效的履约能力，推出全新医美信息服务与预约服务，成立美蜂名医。
        致力于监督、净化行业发展，打造极具社会公信力、信誉度和安全感的健康规范医美平台。
      </p>
      <h5>全国首个创新模式 让求美者与医生直接对话</h5>
      <p>
        美蜂名医APP省掉医院机构和客服层层转诊的繁赘模式和套路玩法，只严格筛选优质医师入驻，直接搭建医生与求美者沟通的桥梁，大大节约时间成本。
        时刻整合领先行业的医生信息，独创豆瓣式权威公正性的消费者打分系统，囊括医生学历信息、工作履历、真实打造案例、好评率、专业技术等各个维度的数据，
        甄别医生水准。真正打造创新型消费者和医生零距离互通模式。
      </p>
      <h5>·以强大优质的医生资源为核心卖点</h5>
      <p>
        美蜂名医基于优质整形医生资源为核心、以一站式管家服务体系为抓手、以数字驱动覆盖用户求美全需求，全场景的医美专业服务平台的战略定位。
        建立全面的医生信息数据库；搭建全链条一站式管家服务体系。从源头将资质不全、技术水准良莠不齐的医师拒之门外。
      </p>
      <h5>·以免费无套路的阳光安全保险为基本保障</h5>
      <p>
        美蜂首次开创为每一位平台消费者以及整形医生提供免费的阳光保险服务，真正为消费者起到保驾护航的作用，同时也给予消费者最贴心，最放心的服务保障。
      </p>
      <h5>一端三口极简模式 去掉中间商赚差价</h5>
      <p>
        据资深医美业从业者透露，线下医美诊所平均获客成本为6000元/人，而以往医疗美容机构有40%甚至更高的市场费用给到平台和渠道，医美机构的利润相应摊薄，这些费用又最终会转嫁到消费者身上。
        美蜂名医采用“一端三口”的极简创新模式，去掉中间商赚差价，直接设立用户端、医生端、管家端，使用户、医生和管家关键通过图文、语音、视频三种模式快速建立沟通渠道，实现更高标准的服务体验。
        美蜂利用供应链优势，以精准用户为敲门砖，吸引整形医生资源，降低医生获客成本，同时也替求美者节约了沟通时间，避免了不透明的消费陷阱。
      </p>
      <h5>战略布局分布全国 成立五大运营中心</h5>
      <p>
        中国医疗美容市场竞争格局呈现高度分散状态，且未来几年分散度会进一步提升。
        医美项目具有消费个性化、地域差异化等特征，因此一般机构很难将运营经验跨省市复制。
        美蜂以五大运营中心为大区市场布点（分别为华南组、华北组、华东组、华西组、华中组），对医疗整形医院提供体系化管理输出。
        并加以控股的经营模式形成品牌连锁布局，由运营中心管理发展所在区域的市场。
        最终由点成面，覆盖全网，服务客户、服务供应商，实现全国互通。
      </p>
      <h5>通过中国商业联合会备案成立美蜂达摩院</h5>
      <p>
        “求质不求量”的初心和坚持是美蜂区格与其他医美平台并且脱颖而出的根本，也是美蜂不沾染医美行业陋习、获取顾客信赖和高信誉度的原因。
        为全面提升服务品质，美蜂互联网成立了专业培训体系的美蜂达摩院，对医生专业技术精进、管家服务标准统一化、公司人才培训体系化进行系统化、体系化管理。让变美这件事，更省心、放心、贴心。
      </p>
      <p>
        未来，美蜂医疗将持续探索创新。秉承全方位服务共享、专业优质医生共享的理念，以领先的专业实力服务美业，智慧的全生态联动布局、安全可靠的一站式服务体验，树立行业安全、健康、专业、规范的新标杆，成为医美消费新趋势的引领者。
      </p>
      <p>美蜂名医即将起航，未来拭目以待。</p>  
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  display: flex;
  margin: 20px 0;
  justify-content: center;
}
.content {
  width: 1200px;
  .title {
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    margin: 10px 0;
    text-align: center;
  }
  .time {
    text-align: center;
    color: #999;
  }
  .img {
    width: 1200px;
    height: auto;
    margin: 10px 0;
  }
}
p {
    color: #999;
}
</style>
